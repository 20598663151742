<template>
    <div class='page'>
        <!-- 导航 -->
        <div class="nav dis_flex items_center">
            <div @click="$utils.backPage()">{{ $t('productDetail.value18') }}</div>
            <div>{{ $t('productDetail.value19') }}</div>
        </div>
        <!-- 产品信息 -->
        <div class="body wow fadeInDown">
            <div class="title">{{ $t('productDetail.value1') }}</div>
            <div class="main dis_flex">
                <div class="main-left">
                    <img class="productDetail1" src="@/assets/images/productDetail1.png" alt="">
                    <div class="info dis_flex items_center flex_column">
                        <div v-if="productInfo.bannerImg" class="swiper">
                            <swiper :options="swiperOption" ref="mySwiper">
                                <swiper-slide v-for="(item, index) in productInfo.bannerImg" :key="index">
                                    <img :src="$utils.getImgUrl(item)" alt="">
                                </swiper-slide>
                            </swiper>
                        </div>
                        <img class="productDetail2" src="@/assets/images/productDetail2.png" alt="" v-else>
                        <div class="title">{{ $i18n.locale == 'zh' ? productInfo.title : productInfo.titleEn }}</div>
                        <div class="line"></div>
                    </div>
                </div>
                <div class="main-right">
                    <!-- 产品优势 -->
                    <div class="subTitle dis_flex items_center">{{ $t('productDetail.value3') }}</div>
                    <div class="items dis_flex items-center wrap wow fadeInDown" v-if="productInfo && productInfo.modelInfo && productInfo.modelInfo.advs">
                        <div class="item flex_btn" v-for="(item, index) in productInfo.modelInfo.advs" :key="index">
                            <div class="dot"></div>
                            <div class="text">{{ $i18n.locale == 'zh' ? item.name : item.nameEn }}</div>
                        </div>
                    </div>
                    <!-- 产品型号 -->
                    <div class="subTitle dis_flex items_center">{{ $t('productDetail.value7') }}</div>
                    <div class="tabs dis_flex items_center wrap" v-if="productInfo && productInfo.modelInfo && productInfo.modelInfo.models">
                        <div class="tab flex_btn" :class="{ 'active': current == index }" v-for="(item, index) in productInfo.modelInfo.models"
                            :key="index" @click="tabChange(index)">{{ item.model }}</div>
                    </div>
                    <div class="trs" v-if="productInfo && productInfo.modelInfo && productInfo.modelInfo.models">
                        <div class="tr dis_flex items_center jus_between">
                            <div class="label">{{ $t('productDetail.value8') }}</div>
                            <div class="value sheng_1">{{ productInfo.modelInfo.models[current].model }}</div>
                        </div>
                        <div class="tr dis_flex items_center jus_between">
                            <div class="label">{{ $t('productDetail.value9') }}</div>
                            <div class="value sheng_1">{{ $i18n.locale == 'zh' ? productInfo.modelInfo.models[current].feature : productInfo.modelInfo.models[current].featureEn }}</div>
                        </div>
                        <div class="tr dis_flex items_center jus_between">
                            <div class="label">{{ $t('productDetail.value10') }}</div>
                            <div class="value sheng_1">{{ $i18n.locale == 'zh' ? productInfo.modelInfo.models[current].spec : productInfo.modelInfo.models[current].specEn }}</div>
                        </div>
                        <div class="tr dis_flex items_center jus_between">
                            <div class="label">{{ $t('productDetail.value11') }}</div>
                            <div class="value sheng_1">{{ $i18n.locale == 'zh' ? productInfo.modelInfo.models[current].package : productInfo.modelInfo.models[current].packageEn }}</div>
                        </div>
                    </div>
                    <div class="btns dis_flex items_center wow fadeInDown">
                        <div class="flex_btn empty" v-if="$i18n.locale == 'zh'" @click="openTry">{{
                            $t('productDetail.value16') }}</div>
                        <div class="flex_btn full" @click="phone">{{ $t('productDetail.value17') }}</div>
                    </div>
                </div>
            </div>
        </div>

        <Pop ref="pop">
            <div class="try">
                <div class="title txt_center">{{ questionnaireInfo.surveyName }}</div>
                <div class="desc txt_center" v-html="questionnaireInfo.surveyDescription"></div>
                <div class="form">
                    <div class="form-item" v-for="(item, index) in questionnaireProblem" :key="item.id">
                        <div class="label">{{ index + 1 + '、' + item.questionName }}</div>
                        <div class="value input" v-if="item.choices.type == 'text' && index != 3">
                            <input type="text" v-model="item.value">
                        </div>
                        <div class="value" v-if="item.choices.type == 'single'">
                            <el-radio-group v-model="item.value">
                                <el-radio v-for="(v, i) in item.choices.options" :key="i" :label="v.optionValue">{{ v.optionValue
                                }}</el-radio>
                            </el-radio-group>
                        </div>
                        <div class="value input" v-if="index == 3">
                            <el-date-picker v-model="item.value" value-format="yyyy-MM-dd" type="date"
                                :clearable="false" placeholder="">
                            </el-date-picker>
                            <img src="@/assets/images/date.png" alt="">
                        </div>
                    </div>

                </div>
                <div @click="submit" class="flex_btn">立即申请</div>
            </div>
        </Pop>
        <el-dialog :visible.sync="dialogVisible" :show-close="false" append-to-body width="540px">
            <div class="dialog">
                <div class="dialog-top">
                    <img class="pop-top"
                        :src="$i18n.locale == 'zh' ? require('@/assets/images/pop-zh.png') : require('@/assets/images/pop-en.png')"
                        alt="">
                </div>
                <div class="dialog-main">
                    <div class="phone">
                        <img src="@/assets/images/productDetail3.png" alt="">
                        <div class="info flex_btn flex_column">
                            <div class="title">{{ $t('productDetail.value20') }}</div>
                            <div class="desc">{{ $t('productDetail.value21') }}: 021-66155558</div>
                            <div class="desc">{{ $t('productDetail.value22') }}:service@concarey.com</div>
                        </div>
                    </div>
                    <div class="phone">
                        <img src="@/assets/images/productDetail3.png" alt="">
                        <div class="info flex_btn flex_column">
                            <div class="title">{{ $t('productDetail.value23') }}</div>
                            <div class="desc">{{ $t('productDetail.value21') }}: 021-66155558</div>
                            <div class="desc">{{ $t('productDetail.value22') }}:service@concarey.com</div>
                        </div>
                    </div>
                    <div class="flex_btn close" @click="close">{{ $t('productDetail.value24') }}</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { productDetail, questionnaireDetail, questionnaireProblem, submitQuestionnaire } from "@/api"
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import Pop from "@/components/pop.vue"
export default {
    components: { Pop, swiper, swiperSlide },
    data() {
        return {
            dialogVisible: false,
            current: 0,
            productInfo: {},
            questionnaireInfo: {},
            questionnaireProblem: [],
            swiperOption: {
                grabCursor: true,
                // loop: true,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true //允许分页点击跳转
                },
            },
            form: {
                value1: '',
                value2: '',
                value3: '',
                value4: '',
                value5: '',
                value6: '',
                value7: '',
                value8: '',
                value9: '',
                value10: '',
                value11: '',
                value12: '',
                value13: '',
                value14: '',
                value15: '',
                value16: '',
                value17: '',
            }
        }
    },
    created() {
        this.$nextTick(() => {   //页面渲染完，在执行
            new this.$wow({
                live: false
            }).init()
        })
        this.getProductDetail()
        this.getQuestionnaireDetail()
        this.getQuestionnaireProblem()
    },
    methods: {
        async submit() {
            try {
                const requestData = {
                    surveyId: this.questionnaireInfo.id,
                    answers: [],
                    tel: this.questionnaireProblem[1].value,
                }
                this.questionnaireProblem.forEach((item, index) => {
                    requestData.answers.push({
                        questionName: item.questionName,
                        questionId: item.id,
                        answerType: item.choices.type,
                        value: item.value,
                        answerType: 1,
                    })
                })
                let res = await submitQuestionnaire(requestData)
                if(res.code == 0){
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    });
                    this.$refs.pop.dialogVisible = false
                    this.questionnaireProblem.forEach(item => {
                        item.value = null
                    })
                }else{
                    this.$message.warning(res.message)
                }
            } catch (err) {
                console.log(err);

            }
        },
        async getQuestionnaireDetail() {
            try {
                const res = await questionnaireDetail({ id: process.env.NODE_ENV == 'development' ? 147 : 151 })//TODO 暂时写死,没有后台配置
                this.questionnaireInfo = res.data
            } catch (err) {
                console.log(err);
            }
        },
        async getQuestionnaireProblem() {
            const res = await questionnaireProblem({ id: process.env.NODE_ENV == 'development' ? 147 : 151 })//TODO 暂时写死,没有后台配置
            this.questionnaireProblem = res.data
            this.questionnaireProblem.forEach((item, index) => {
                item.choices = JSON.parse(item.choices)
                this.$set(item, 'value', null)
            })
        },
        async getProductDetail() {
            try {
                const res = await productDetail({ id: this.$route.query.id })
                res.data.modelInfo = JSON.parse(res.data.modelInfo)
                if(res.data.bannerImg){
                    res.data.bannerImg = res.data.bannerImg.split(',')
                }
                this.productInfo = res.data
            } catch (err) {
                console.log(err);
            }
        },
        tabChange(index) {
            this.current = index
        },
        openTry() {
            this.$refs.pop.dialogVisible = true
        },
        phone() {
            this.dialogVisible = true
        },
        close() {
            this.dialogVisible = false
        }
    },
}
</script>
<style scoped lang='scss'>
.page {
    padding-top: 250px;

    .nav {
        padding: 0 54px 17px;
        border-bottom: 1px solid #D9D9D9;

        div {
            padding: 0 15px;
            border-right: 1px solid #E9E9E9;
            color: #101887;
            font-size: 16px;
            cursor: pointer;

            &:last-child {
                border-right: none;
                font-weight: bold;
            }
        }
    }
}

.body {
    padding: 40px 160px 66px;
    box-sizing: border-box;

    .title {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 44px;
    }

    .main {
        .main-left {
            position: relative;

            .productDetail1 {
                width: 580px;
                height: 720px;
            }

            .info {
                padding-top: 40px;
                box-sizing: border-box;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                .swiper{
                    width: 500px;
                    img{
                        width: 500px;
                        height: 500px;
                        object-fit: cover;
                    }
                }
                .productDetail2 {
                    width: 500px;
                    height: 500px;
                }

                .title {
                    font-size: 38px;
                    margin: 22px 0 36px;
                }

                .line {
                    width: 96px;
                    height: 8px;
                    background: #00239C;
                }
            }
        }

        .main-right {
            margin-left: 50px;
            width: calc(100% - 630px);

            .subTitle {
                width: 100%;
                height: 60px;
                background: rgba($color: #00239C, $alpha: .1);
                color: #00239C;
                font-size: 26px;
                font-weight: bold;
                padding-left: 31px;
                box-sizing: border-box;
            }

            .items {
                margin: 30px 0 83px;

                .item {
                    line-height: 1.2;
                    position: relative;
                    box-sizing: border-box;
                    padding: 0 20px;
                    margin-bottom: 10px;
                    // flex: 1;

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 1px;
                        height: 40px;
                        background: #857575;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:last-child {
                        &::after {
                            content: none;
                        }
                    }

                    // &:first-child {
                    //     padding-left: 0;
                    // }

                    .dot {
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        background: #E3E3E3;
                        margin-right: 15px;
                        flex-shrink: 0;
                    }

                    .text {
                        font-size: 22px;
                        font-weight: bold;
                        white-space: nowrap;
                    }
                }
            }

            // .table{
            //     margin-top: 44px;
            //     width: 100%;
            //     overflow-x: auto;
            //     padding-bottom: 20px;
            //     table{
            //         table-layout: auto;
            //         border-collapse: collapse;
            //         outline: none;
            //     }
            //     thead{
            //         th{
            //             font-size: 24px;
            //             font-weight: bold;
            //             text-align: center;
            //             padding: 0 30px 30px;
            //             white-space: nowrap;  
            //         }
            //     }
            //     tbody{
            //         tr{
            //             height: 60px;
            //             td{
            //                 padding: 0 30px;
            //                 font-size: 18px;
            //                 white-space: nowrap;  
            //             }
            //             border-bottom: 1px solid #D8D8D8;
            //             &:nth-child(2n-1){
            //                 background: #F9F9F9;
            //             }
            //         }
            //     }
            // }
            .tabs {
                margin-top: 30px;

                .tab {
                    margin-right: 35px;
                    padding: 0 25px;
                    width: fit-content;
                    height: 50px;
                    white-space: nowrap;
                    color: #818181;
                    font-size: 20px;
                    font-weight: bold;
                    cursor: pointer;
                    transition: .3s;
                    margin-bottom: 30px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &.active {
                        background: #00239C;
                        color: #fff;
                    }
                }
            }

            .trs {
                width: 100%;

                .tr {
                    padding: 20px;
                    box-sizing: border-box;
                    border-top: 1px solid #D8D8D8;
                    font-size: 18px;

                    &:nth-child(2n-1) {
                        background: #F9F9F9;
                    }

                    .label {
                        font-weight: bold;
                    }

                    .value {
                        max-width: 70%;
                    }
                }
            }

            .btns {
                margin-top: 78px;
                justify-content: flex-end;

                .flex_btn {
                    text-align: center;
                    width: 220px;
                    height: 65px;
                    font-size: 20px;
                    font-weight: bold;
                    cursor: pointer;

                    &.empty {
                        border: 1px solid #00239C;
                        color: #00239C;
                    }

                    &.full {
                        background: #00239C;
                        color: #fff;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}

.try {
    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 5px;
        line-height: 1.2;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .desc {
        width: 80%;
        color: #5D5D5D;
        font-size: 12px;
        margin: 0 auto;
    }

    .form {
        height: 350px;
        overflow: auto;
        padding: 0 26px;
        box-sizing: border-box;

        .form-item {
            padding-top: 40px;

            &:last-child {
                padding-bottom: 40px;
            }

            .label {
                font-size: 18px;
                font-weight: bold;
                line-height: 1.2;
            }

            .value {
                margin-top: 6px;
                position: relative;

                &.input {
                    height: 50px;
                    border-bottom: 1px solid #000000;
                }

                input {
                    width: 100%;
                    height: 100%;
                }

                img {
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .el-radio-group {
                    display: flex;
                    flex-direction: column;

                    .el-radio {
                        margin-top: 5px;

                        ::v-deep .el-radio__inner {
                            width: 18px;
                            height: 18px;
                        }

                        ::v-deep .el-radio__label {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .flex_btn {
        width: 198px;
        height: 54px;
        background: #00239C;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        margin: 20px auto 0;
        cursor: pointer;
    }
}

.dialog {
    border-radius: 18px;
    overflow: hidden;
    padding-bottom: 27px;

    .dialog-top {
        width: 100%;
        position: relative;

        .pop-top {
            width: 100%;
            object-fit: cover;
        }

        .pop-close {
            width: 25px;
            height: 25px;
            position: absolute;
            top: 22px;
            right: 17px;
        }
    }

    .dialog-main {
        background: #fff;
        border-radius: 18px;
        width: 100%;
        padding: 50px 50px 27px;
        box-sizing: border-box;
        transform: translateY(-18px);

        .phone {
            width: 100%;
            height: 140px;
            position: relative;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }

            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .info {
                position: relative;
                height: 100%;
            }

            .title {
                color: #00239C;
                font-size: 24px;
                font-weight: bold;
                line-height: 1.2;
            }

            .desc {
                margin-top: 14px;
                font-size: 16px;
                line-height: 1.2;
            }
        }

        .close {
            width: 198px;
            height: 54px;
            border: 1px solid #00239C;
            color: #00239C;
            font-weight: bold;
            margin: 0 auto;
            cursor: pointer;
            font-size: 16px;
        }
    }
}

::v-deep .el-dialog {
    border-radius: 18px;
    background: transparent;
    box-shadow: none;

    .el-dialog__header {
        padding: 0;
    }

    .el-dialog__body {
        padding: 0;
    }
}

::v-deep .el-date-editor.el-input {
    width: 100%;
}

::v-deep .el-input__inner {
    border: none;
    padding-left: 0 !important;
}

::v-deep .el-input__prefix {
    display: none;
}

input {
    border: 0;
    /*清除自带的2px的边框*/
    padding: 0;
    /*清除自带的padding间距*/
    outline: none;
    /*清除input点击之后的黑色边框*/
}

::-webkit-scrollbar {
    background-color: #ECECEC;
    border-radius: 4px;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #00239C;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #00239C;
}
</style>
